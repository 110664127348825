<template>
  <div 
    id="idg__page_component__animated_fade_in" 
    ref="datagrid_page_component" 
    class="animated fadeIn" 
    onscroll="scroll(this)"
  >
    <div class="table-container">
      <div class="list-wrapper">
        
          <div class="page_datagrid_content" :style="styleLayout">
            <j-data-grid-title 
              v-if="showInfoBox"
              :attrs="titleAttrs" 
              :legend-items="tableLegend" 
              :pagination="pagination"
              :chart-component-name="chartComponentName" 
              :chart-data="chartData" 
              @first="onPageTo(1)"
              @previous="onPageTo"
              @next="onPageTo"
              @last="onPageTo"
              @excel-download="excelDownload"
              @input-filter="onInputFilter"
              @request-action="onRequestedAction"
            />

            <j-datatable-p6
              v-if="dtComponentName == 'JDatatableP6'"
              ref="datatable"
              item-key="NO"
              freeze-column="0"
              :idx="dataOrigianlID"
              :filtered-values="filteredValuesTrimed"
              :filter-string="filterString"
              :input-filter="inputFilter"
              :headers="headers"
              :items="items"
              :summaries="summaries"
              :preset-source="presetSource"
              :table-attrs="tableAttrs"
              :table-type="'P6'"
              :title-attrs="titleAttrs" 
              @click-config="onConfigClicked"
              @complete="onComplete"
              @flattened="onFlattened"
              @header-props-assigned="onHPropsAssigned"
              @request-action="onRequestedAction"
              @update-preset="onUpdatedPreset"
              @pregress="onProgress"
              @loading="onLoading"
            />
            <j-data-grid-grouped-column
              v-else
              ref="datatable"
              item-key="NO"
              freeze-column="0"
              :headers="headers"
              :items="items"
              :summaries="summaries"
              :preset-source="presetSource"
              :table-attrs="tableAttrs"
              :table-type="typeName"
              :title-attrs="titleAttrs" 
              @click-config="onConfigClicked"
              @complete="onComplete"
              @flattened="onFlattened"
              @header-props-assigned="onHPropsAssigned"
              @request-action="onRequestedAction"
              @update-preset="onUpdatedPreset"
              @chart-to-xml-string="onChart2XmlString"
              @pregress="onProgress"
            />
          </div>

          <service-intended-datagrid-config-modal
            v-model="configOpend"
            :items="flattened"
            @edit="onConfigUpdated"
          />

      </div>
    </div>

    <j-alert
      v-model="msgOpen"
      :type="msgInfo.type"
      :title="msgInfo.title"
      :titleDescription="msgInfo.titleDescription"
      :message="msgInfo.message"
      :button="msgInfo.button"
      :buttonText="msgInfo.buttonText"
      @yes="yes()"
      @cancel="msgOpen = false"
    ></j-alert>

    <j-to-top v-if="scrollTopVisible" class="data_grid_totop" @scrolled="scrollFn"/>

    <j-modal-slide-component 
      v-model="modalOpen"
      :component="component"
      :filters="filters"
      :target="target"
      @request-reload="reload"
    />

  </div>
</template>

<script>
import * as d3 from 'd3'

import __C from '@/primitives/_constant_'
import { mapState, mapMutations, mapGetters, mapActions } from "vuex"
import { DBSupportService } from "@/services"

import Loading from '@/mixins/loading.mixin'
import JServiceComponents from '@/components/JServiceComponentTargets'
import KLibDatatableSummaryCharts from '@/components/KLibDatatableSummaryCharts'
import ServiceIntendedDatagridConfigModal from '@/views/service/modal/ServiceIntendedDatagridConfig.modal'
import JToTop from '@/components/JToTop.vue'
import Url from '../../../src/services/api/request.url'
import JDatatableP6 from '../../../lib/jin/uicomponents/JDatatableP6'

export default {
  name: "service-intended-datagrid",
  components: {
    JDatatableP6,
    ...JServiceComponents,
    ...KLibDatatableSummaryCharts,
    JToTop,
    ServiceIntendedDatagridConfigModal,
  },
  mixins: [
    Loading
  ],
  data: () => ({
    systemCodeService: null,
    queryLibService: null,
    
    dtComponentName: 'JDataGridGroupedColumn',
    configOpend: false,
    execRequested: [],
    flattened: [],
    presetSource: [],
    sleepRequest: false,
    chartList: [],
    inputFiltered: false,
    headerProperties: null,

    component: '',
    filters: {},
    target: {},
    keyValue: null,
    prevScrollTop: null,
    scrollTopVisible: false,
    chartXmlString: null,

    chartData: null,    
    msgOpen: false,
    msgInfo: {
      type: "",
      title: "",
      titleDescription: "",
      message: "",
      button: [true, false, true],
      buttonText: ["Yes", "No", "Cancel"]
    },
    yes: () => { },
  }),
  computed: {
    ...mapState(__C.STORE_NAMESPACE.APPLICATION, [
      'navState'
    ]),
    ...mapState(__C.STORE_NAMESPACE.ACCOUNT, ['account']),
    ...mapState(__C.STORE_NAMESPACE.APP_SERVICE, [
      'child',
      'modalOpened',
      'popupFreeOpened', 
      'popupFreeCallback'
    ]),
    ...mapState(__C.STORE_NAMESPACE.INTENDED_DATAGRID, [
      'datagridID',
      'dataOrigianlID',
      'iFilter',
      'filteredValues',
      'filterString',
      'inputFilter',
      'resultSet'
    ]),
    ...mapGetters(__C.STORE_NAMESPACE.INTENDED_DATAGRID, [
      'filteredValuesTrimed',
      'pagination'
    ]),

    chartComponentName() {
      let info = this.headers.find(h => h.type == 'info')
      if(!info) return null

      return info.chartComponentName || null
    },

    showInfoBox() { return this.tableAvailable && this.tableAttrs.table.showInfoBox == 'Y' },
    styleLayout() {
      if (
        !this.tableAttrs.table || 
        !this.tableAttrs.table.layout ||
        this.tableAttrs.table.layout.applied != 'Y'
      ) return ''
      return `background-color: ${this.tableAttrs.table.layout.bColor}; padding: ${this.tableAttrs.table.layout.margin}px;`
    },
    tableAvailable() { return !this.tableAttrs || !this.tableAttrs.table || Object.keys(this.tableAttrs.table).length === 0 ? false : true },

    charts() {
      if (!this.resultSet.chartValues) return {}
      return this.resultSet.chartValues
    },
    chartExist() { return Object.keys(this.charts).length > 0 ? true : false },
    headers() {
      if (!this.resultSet.dataProps) return []
      return this.resultSet.dataProps
    },
    externalExport() {
      let info = this.headers.find(h => h.type == 'info')
      if(!info || !info.export) return null

      return info.export.idx
    },
    isInfiniteScroll() { 
      if(
        !this.resultSet.tableAttrs ||
        !this.resultSet.tableAttrs.table ||
        !this.resultSet.tableAttrs.table.pagination ||
        !this.resultSet.tableAttrs.table.pagination.type
      ) return false

      return this.tableAttrs.table.pagination.type == 'scroll' 
    },
    items() {
      if (!this.resultSet.dataList) return []
      return this.resultSet.dataList
    },
    modalOpen: {
      get() { return this.modalOpened },
      set(val) { this.setModalOpened(val) },
    },
    summaries() {
      if (!this.resultSet.summaryData) return []
      return this.resultSet.summaryData
    },
    tableAttrs() {
      if (!this.resultSet.tableAttrs) return {}
      return this.resultSet.tableAttrs
    },
    tableLegend() { 
      if (
        !this.resultSet.tableAttrs || 
        !this.resultSet.tableAttrs.style ||
        Object.keys(this.resultSet.tableAttrs.style).length === 0
      ) return {}
      return this.resultSet.tableAttrs.style
    },
    titleAttrs() {
      if (
        !this.resultSet.tableAttrs || 
        !this.resultSet.tableAttrs.table
      ) return {}

      let attrs_ = {
        ...JSON.parse(JSON.stringify(this.resultSet.tableAttrs.title || {})),
        ...JSON.parse(JSON.stringify(this.resultSet.tableAttrs.table)),
      }
      attrs_.queries = this.resultSet.titleData
      if(!attrs_.main) attrs_.main = {}
      if(!attrs_.main.text) attrs_.main.text = this.navState.name

      return attrs_
    },
    
    catCodePage() { return this.resultSet && this.resultSet.catCode == __C.PAGE_COMPONENT.TYPE_PAGE },
    catCodeTimeline() { return this.resultSet && this.resultSet.catCode == __C.PAGE_COMPONENT.P6 },

    typeName() { return this.resultSet.tableType || '' },
    chartType() { return this.charts.ChartType },
    // chartDatabase() { return this.extractData(__C.CHART.ATTR_NAME_CANVAS) },
    chartCanvas() { return this.extractData(__C.CHART.ATTR_NAME_CANVAS) },
    chartCircle() { 
      let circle_ = this.extractData(__C.CHART.ATTR_NAME_CIRCLE)

      if(circle_.CircleBkColor) circle_.CircleBkColor = JSON.parse(circle_.CircleBkColor)
      if(circle_.CircleFtColor) circle_.CircleFtColor = JSON.parse(circle_.CircleFtColor)
      if(circle_.CircleGaugeColor) circle_.CircleGaugeColor = JSON.parse(circle_.CircleGaugeColor)
      if(circle_.CircleGaugeCount) circle_.CircleGaugeCount = JSON.parse(circle_.CircleGaugeCount)
      if(circle_.CircleGaugeLength) circle_.CircleGaugeLength = JSON.parse(circle_.CircleGaugeLength)
      if(circle_.CircleGaugeRadius) circle_.CircleGaugeRadius = JSON.parse(circle_.CircleGaugeRadius)
      if(circle_.CircleGaugeStroke) circle_.CircleGaugeStroke = JSON.parse(circle_.CircleGaugeStroke)
      if(circle_.CircleMultiBkColors) circle_.CircleMultiBkColors = JSON.parse(circle_.CircleMultiBkColors)
      if(circle_.CircleMultiFtColors) circle_.CircleMultiFtColors = JSON.parse(circle_.CircleMultiFtColors)
      if(circle_.CircleRadius) circle_.CircleRadius = JSON.parse(circle_.CircleRadius)
      if(circle_.CircleStroke) circle_.CircleStroke = JSON.parse(circle_.CircleStroke)

      return circle_
    },
    chartTitle() { return this.extractData(__C.CHART.ATTR_NAME_TITLE) },
    chartLegends() { return this.extractData(__C.CHART.ATTR_NAME_LEGENDS) },
    chartNote() { return this.extractData(__C.CHART.ATTR_NAME_NOTE) },
    chartPackage() { return this.extractData(__C.CHART.ATTR_NAME_PACKAGE) },
    chartValue() { return this.charts.Values ? JSON.parse(this.charts.Values) : [] },
    chartItems() { return this.charts.JsonString ? JSON.parse(this.charts.JsonString) : [] },
  },
  watch: {
    'resultSet.dataList': {
      handler() {
        let info = this.headers.find(h => h.type == 'info')

        if(info && info.chartQueryName) {
          this.queryLibService.getSqlQueryResult({
            idx         : 0, 
            name        : info.chartQueryName,
            filterType  : 'json',
            filters     : this.filteredValuesTrimed ? JSON.stringify(this.filteredValuesTrimed) : '{}',
            filterString: '',
            jsonProps   : JSON.stringify(this.headers),
            inputFilter : this.inputFilter,
          }).then(res => {
            this.chartData = res
          })          
        }
      },
      deep: true
    }
  },
  beforeCreate() {
    this.loading = true
  },
  created() {
    this.queryLibService = new DBSupportService()
    
    this.setChild(__C.STORE_NAMESPACE_KEY.DATATABLE)
    this.setPagecallFunc(this.run)
    
    this.queryLibService.getSqlQueryResult({
      idx: 0, 
      name: 'getCatCode',
      filters: ` [SUB_ITEM] = '${this.navState.id}'`
    }).then(res => {
      if (!res) {
        this.cmsCatCode = null
        return
      }
      if (res[0].CAT_CODE == 'P6') {
        this.dtComponentName = 'JDatatableP6'
      }
    })

  },
  mounted() {
    window.document.documentElement.querySelector('#app .wrap__contents').addEventListener("scroll", this.onScroll)
  },
  beforeDestroy() {
    window.document.documentElement.querySelector('#app .wrap__contents').removeEventListener("scroll", this.onScroll)
  },
  methods: {
    ...mapMutations(__C.STORE_NAMESPACE.APPLICATION, [
      'setPagecallFunc'
    ]),
    ...mapMutations(__C.STORE_NAMESPACE.APP_SERVICE, [
      'setChild', 
      'setModalOpened',
      'setPopupFreeOpened',
      'setPopupEqOpened',
    ]),
    ...mapActions(__C.STORE_NAMESPACE.APP_SERVICE, [
      'updateFilteredValues'
    ]),
    ...mapMutations(__C.STORE_NAMESPACE.INTENDED_DATAGRID, [
      'setChartFilter',
      'setFilterString',
      'setInputFilter',
      'setPageRows',
      'setPageNumber',
    ]),
    ...mapActions(__C.STORE_NAMESPACE.INTENDED_DATAGRID, [
      'initService',
      'sendRequest',
      'updateConfigProps',
      'updateChartFilter',
      'GET_EXCEL',
    ]),
    
    run() {
      return this.initService().then(() => { 
        this.setInputFilter("")
        return this.sendRequest('init') 
      })
    },
    reload() {
      this.sendRequest('filtered')
    },
    onChartClick(chart) {
      if (!chart.qFilters) return

      this.loading = true

      this.updateChartFilter({
        chartNo: chart.chartNo,
        qFilters: chart.qFilters
      }).then(() => {
        // get PATRONAGE Data
        this.sendRequest('filtered')
      })
    },
    onChartComplete(svgID, chart) {
      let dataAvailable = this.resultSet.dataList && this.resultSet.dataList.length > 0

      if (!dataAvailable) return
      // Chart {
      //   chartNo: String
      //   type: String
      //   values: Object
      //   jsonProps: Object
      // }
      Object.keys(chart.values).forEach(name => {
        // chart's input fields are already mapped with 
        // the key-name in chart service.
        let chartSVG = this.mapDonutService.chart(d3.select(`#${svgID}`), chart.type)
        chartSVG[name].set(dataAvailable ? chart.values[name] : 0)
      })

      this.chartList = this.chartList.concat([{
        svgID: svgID,
        chart: chart
      }])
    },
    onComplete() {
      this.loading = false
    },
    onLoading(v) {
      this.loading = v
    },
    onConfigClicked(e) {
      this.configOpend = true
    },
    onConfigUpdated(v) {
      this.configOpend = false

      // v (config Values/Propperties) : updated properties' data structure
      // {
      //   uFilteredValues: {
      //     COLUMN_NAME: "<String|Number|Array>",
      //     ...
      //   },
      //   uConfigProps: <Object Array> 
      // }

      // uFilteredValues :
      // by the filtering options are changed, its values should also be changed to.
      this.updateFilteredValues(v.uFilteredValues).then(res => {

        // uConfigProps :
        // updated 'flattened' datagrid supplied to the config modal.
        // After sending the user updated configuration values to 
        // 'presetSource', 'onUpdatedPreset' will revieve a preset of 
        // the configuration values not stored yet.
        this.presetSource = v.uConfigProps

        // so that storing the updated configuration values to the local
        // storage is processed in the method 'onUpdatedPreset'.
      })
    },
    onFlattened(f) {
      this.flattened = [...f]
    },
    onHPropsAssigned(props) {
      this.headerProperties = props
    },
    onInputFilter(val) {
      if(val) this.inputFiltered = true
      else this.inputFiltered = false

      this.setInputFilter(val)
      this.sendRequest('input')
    },
    onPageTo(p) {
      this.loading = true
      this.setPageNumber(p)
      this.sendRequest('page')
    },
    onProgress(e) {

    },
    onRequestedAction(request) {
      if(!request.action) {
        console.log(`[USER: undefined 'action'] Target is not defined.`)
        return
      }

      if(request.action.target == 'append-filter-string') {
        this.setFilterString(request.filterString)
        this.sendRequest('filtered')
        return
      }

      if(request.action.target == 'Download') {
        // In Piping Test Package - Testpack-wise Summary
        // Only Test Package File Download

        if (!request.filters.TEST_PACKAGE) return
        var iframe = document.createElement('iframe')
        document.body.appendChild(iframe)
        iframe.src = `${__C.HOST_NAME_API}/v0/filebrowser/raw?path=/TORTUE/TestPackage/${request.filters.TEST_PACKAGE}.pdf&name=&`

        return
      }

      // For the URL process
      if(request.action.target != __C.REQUEST_ACTION.TARGET_URL && !request.action.component) {
        console.log(`[USER: undefined 'Component Name'] Target Name is not defined.`)
        return
      }
      if(request.action.target == __C.REQUEST_ACTION.TARGET_URL && !request.action.path) {
        console.log(`[USER: undefined 'URL Path'] URL Path is not defined.`)
        return
      }
      if(request.action.target == __C.REQUEST_ACTION.TARGET_URL) {
        window.open(request.action.path)
        return
      }
      // For the Pop-Up process
      if(request.action.target != __C.REQUEST_ACTION.TARGET_POPUP && !request.action.component) {
        console.log(`[USER: undefined 'Component Name'] Target Name is not defined.`)
        return
      }

      if([
        __C.REQUEST_ACTION.TARGET_POPUP,
        __C.REQUEST_ACTION.TARGET_ROOM_POPUP
      ].includes(request.action.target)) {
        this.setPopupEqOpened({
          open: true,
          queryid: request.action.id,
          filters: request.filters
        })
        return
      }
      if(__C.REQUEST_ACTION.TARGET_POPUP_FREE == request.action.target) {
        this.setPopupFreeOpened({
          open: true,
          component: request.action.component,
          queryid: request.action.id,
          filters: request.filters,
          iFilters: request.iFilters,
          data: request.data,
        })
        return
      }
      
      // For the Slide-Modal process
      this.setModalOpened(true)
      this.component = request.action.component
      this.target = {
        code: __C.PAGE_COMPONENT.TYPE_MODAL,
        type: '',
        id: request.action.id,
        no: request.action.no,
      }
      this.filters = {
        // filters: request.dataType == 'summary' ? (this.filteredValuesTrimed || {}) : request.filters,
        filters: { ...request.filters, ...this.filteredValuesTrimed },
        iFilters: { ...request.iFilters, inputFilter: this.inputFilter }
      }

      console.log(`[USER: #DEBUG] Target  [0]: ${this.component} ${JSON.stringify(this.target)}`)
      console.log(`[USER: #DEBUG] Filters [1]: ${JSON.stringify(this.filters.filters)}`)
      console.log(`[USER: #DEBUG] iFilters[2]: ${JSON.stringify(this.filters.iFilters)}`)
    },
    onScroll(e) {
      if(!this.isInfiniteScroll) return
      
      let taget_ = e.currentTarget
      this.scrollTopVisible = taget_.scrollTop > 100

      if(this.sleepRequest || taget_.offsetHeight + taget_.scrollTop < taget_.scrollHeight) return
      if(this.prevScrollTop && this.prevScrollTop >= taget_.scrollTop) {
        this.prevScrollTop = taget_.scrollTop
        return
      }
      this.prevScrollTop = taget_.scrollTop
      this.register(() => {
        this.loading = true
        this.sleepRequest = true
        this.setPageNumber(this.pagination.page + 1)
        this.sendRequest('scroll').then(res => {
          if(!res) this.loading = false
          setTimeout(() => { 
            this.sleepRequest = false 
          }, 1000)
        })
      })
    },
    onUpdatedPreset(preset) {
      this.updateConfigProps(preset).then(res => {
        // reload the data
        this.sendRequest()
      })
    },
    onChart2XmlString(v) {
      this.chartXmlString = v
    },
    async excelDownload() {
      this.loading = true      
      this.msgOpen = false
      if(this.pagination.totalRows){
        
        console.log(`[USER: #DEBUG] [EXCEL TOTALROWS: ${parseInt(this.pagination.totalRows,10)}]`)
        console.log(`[USER: #DEBUG] [excelExportLimit: ${parseInt(this.tableAttrs.table.excelExportLimit,10)}]`)
        //console.log(`[USER: #DEBUG] [EXCEL]`,parseInt(this.pagination.totalRows) > parseInt(this.tableAttrs.table.excelExportLimit)? 'TRUE':'FALSE')
        if(parseInt(this.pagination.totalRows,10) > ( parseInt(this.tableAttrs.table.excelExportLimit,10) ? parseInt(this.tableAttrs.table.excelExportLimit,10) :1000) ) {
          this.loading = false
          //alert(`The number of total rows(${this.pagination.totalRows}) is over ${this.tableAttrs.table.excelExportLimit}.\n Please narrow down the search criteria.`)
          this.msgInfo.type = "WARN"
          this.msgInfo.title = "Can Not Download"
          this.msgInfo.titleDescription = ""
          this.msgInfo.message = `The number of total rows(${this.pagination.totalRows}) is over ${this.tableAttrs.table.excelExportLimit}.<br> Please narrow down the search criteria.`
          this.msgInfo.button = [false, false, true]
          this.msgOpen = true
          return
        }
      }

      let fValues = this.filteredValuesTrimed == null ? "" : JSON.stringify(this.filteredValuesTrimed)
      let ifilter = JSON.stringify({ filterString: this.filterString, inputFilter: this.inputFilter })

      let params = new URLSearchParams()
      params.append('idx', this.externalExport || this.dataOrigianlID)    // menu-item or sub-item's idx
      params.append('fValues', fValues)
      params.append('iFilter', ifilter)
      params.append('easyFormedHeaders', JSON.stringify(this.easyFormedHeaders))
      params.append('pagination', JSON.stringify({page:1,rowsPerPage:40,reset:1}))
      params.append('chartXmlProps', this.chartXmlString ? JSON.stringify(this.chartXmlString) : '')
      params.append('requestType', 'full')
      params.append('token', this.account.token)

      console.log(`[USER: #DEBUG] IDX     [0]: ${this.externalExport || this.dataOrigianlID}`)
      console.log(`[USER: #DEBUG] Filters [1]: ${fValues}`)
      console.log(`[USER: #DEBUG] iFilters[2]: ${ifilter}`)

      const send_Data = {
        url_ : `${Url.fetch.excel.get}?${params.toString()}`,
        title : this.titleAttrs.main.text
      }

      await this.GET_EXCEL(send_Data)
    
      // For creating the excel file by javascript lib on the browser ----
      // let res_ = await this.sendRequest('full')
      // let data_ = {
      //   title: this.titleAttrs,
      //   legend: this.tableLegend,
      //   items: res_
      // }

      // let excel = await this.$refs.datatable.toxlsx(data_)
      // if(!excel) {
      //   this.loading = false
      //   return
      // }

      // let url = window.URL.createObjectURL(excel)
      // let a = document.createElement('a')
      // document.body.appendChild(a)
      // a.href = url
      // a.download = this.titleAttrs.main.text
      // a.click()
      // window.URL.revokeObjectURL(url)
      // document.body.removeChild(a)
      // -----------------------------------------------------------------
      
      this.loading = false
    },
    extractData(typeName) {
      let chartData = this.charts
      let keyNames = Object.keys(chartData).filter(k => k.toLowerCase().indexOf(typeName.toLowerCase()) === 0)
      let data = {}
      keyNames.forEach(k => { data[k] = chartData[k] })

      return data
    },

    exec(requested) {
      if(requested.num != this.execRequested.length) return
      
      this.execRequested = []
      requested.executor()
    },
    register(executor) {
      let requested = {
        num: this.execRequested.length + 1,
        time: new Date().getTime(),
        executor: () => { executor() }
      }
      this.execRequested.push(requested)
      setTimeout(() => { this.exec(requested) }, 100)
    },    

    scrollFn() {
      window.document.documentElement.querySelector('#app .wrap__contents')
      .scrollTo({top: 0, behavior: 'smooth'})
    },
  }
}

</script>

<style lang="stylus" scoped>
.page_datagrid_content {
  .j_datagrid_title {
    position: sticky;
    left: 0;
  }
}


</style>
